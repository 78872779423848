import { useContext, useEffect } from 'react';
import {isMobile} from 'react-device-detect';
import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';

const APP_MODE = 2; //개발(0), 테스트(1), 라이브(2)
const LOG_ENABLE = APP_MODE === 0;
const APP_VERSION = "1.1.8";

//api2호출 경로
const API_URL_DEV = "https://test2.bravo6.kr:5001/"; //개발서버
const API_URL_TEST = "https://test.snsfly.kr:5001/"; //테스트 서버
const API_URL_LIVE = "https://api.snsfly.kr/"; //라이브서버

//const SNS_API_DEV = "http://test2.bravo6.kr:3001/"; //개발
const SNS_API_DEV = "https://test.snsfly.kr:3002/"; //테스트
const SNS_API_TEST = "https://test.snsfly.kr:3002/"; //테스트
const SNS_API_LIVE = "https://api2.snsfly.kr/"; //라이브서버

//snsfly 웹사이트 기본 이미지 경로
const IMG_URL_LIVE = "https://cdn.snsfly.kr/cashp/mpads/";
const IMG_URL_TEST = "https://test.snsfly.kr/cashp/mpads/";
const IMG_URL_DEV = "https://test2.bravo6.kr/cashp/mpads/";
//const IMG_URL_DEV = "https://test.snsfly.kr/cashp/snsfly/";

const TEMP_IMG_URL_LIVE = "https://cdn.snsfly.kr/cashp/temp/";
const TEMP_IMG_URL_TEST = "https://test.snsfly.kr/cashp/temp/";
const TEMP_IMG_URL_DEV = "https://test2.bravo6.kr/cashp/temp/";

//빙고팡팡 심볼 및 각종이미지 주소
const IMG_BINGO_DEV = "https://test2.bravo6.kr/cashp/bs/";
const IMG_BINGO_TEST = "https://test.snsfly.kr/cashp/bs/";
const IMG_BINGO_LIVE = "https://cdn.snsfly.kr/cashp/bs/";

const FIRST_PAGE_INDEX = 0; //로그인시 처음진입하는 페이지 번호

const INSTAGRAM_CHECK_MODE = 1; //스크랩핑서버방식(0), 백그라운드 후 스크랩핑 방식(1)

let mobileMode = false;//isMobile || true;
let mobileOn = isMobile || mobileMode;


function GetSNSApiUrl(url)
{
  switch(APP_MODE)
  {
    case 0:
      return `${SNS_API_DEV}${url}`;
    case 1:
      return `${SNS_API_TEST}${url}`;
    case 2:
    default:
      return `${SNS_API_LIVE}${url}`;
  }
}

function GetApiUrlBase()
{
  switch(APP_MODE)
  {
    case 0:
      return API_URL_DEV;
    case 1:
      return API_URL_TEST;
    case 2:
    default:
      return API_URL_LIVE;
  }
}

function GetApiUrl(url)
{
  return `${GetApiUrlBase()}${url}`;
}

function GetUploadApiUrl(url)
{
  if (APP_MODE === 2)
    return `https://upload.snsfly.kr:5001/${url}`;
  else
    return GetApiUrl(url);
}

//이미지 리소스 파일의 경로를 얻음
function GetImgUrl(imageName)
{
  switch(APP_MODE)
  {
    case 0://개발
      return IMG_URL_DEV + imageName;
    case 1://테스트
      return IMG_URL_TEST + imageName;
    case 2://라이브
      return IMG_URL_LIVE + imageName;
    default:
      return null;
  }
}

function GetTempImgUrl(imageName)
{
  switch(APP_MODE)
  {
    case 0://개발
      return TEMP_IMG_URL_DEV + imageName;
    case 1://테스트
      return TEMP_IMG_URL_TEST + imageName;
    case 2://라이브
      return TEMP_IMG_URL_LIVE + imageName;
    default:
      return null;
  }
}

const getBingoImageUrl = (name) =>
{
  switch(APP_MODE)
  {
    case 0:
      return `${IMG_BINGO_DEV}${name}`;
    case 1:
      return `${IMG_BINGO_TEST}${name}`;
    case 2:
      return `${IMG_BINGO_LIVE}${name}`;
    default:
      return name;
  }
}

function handleAppNavi(navigation, naviIndex)
{
  switch(naviIndex)
  {
    case 0: //주문하기
      navigation('/order');
      return;
    case 1: //주문내역
      navigation('/orderlist');
      return;
    case 2: //자주묻는질문
      navigation('/faq');
      return;
    case 3: //공지사항
      navigation('/notice');
      return;
    case 4: //충전하기
      navigation('/purchase');
      return;
    case 5: //회원가입
      navigation('/register');
      return;
    case 6: //계정셋팅
      navigation('/setting');
      return;
    case 7: //빙고팡팡주문페이지
      navigation('/bingo');
      return;
    case 100: //메인페이지
      navigation('/');
      return;
    case 101: //회원탈퇴
      navigation('/unreg');
      return;
    default:
      return;
  }
}

//카카오톡 상담하기 페이지로 이동
function gotoTalkPage()
{
  window.open('https://pf.kakao.com/_nVBxoG/chat');
}

function withNavigation(Component)
{
  return props => <Component {...props} navigate={useNavigate()} />;
}

function gotoFirstPage()
{
  if (APP_MODE === 0)
    window.location.replace("http://localhost:3000/");
  else if (APP_MODE === 1)
    window.location.replace("https://test.snsfly.kr/");
  else
    window.location.replace("https://ads.mpang.io/");
}

const useBackListener = (callback) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    const listener = ({ location, action }) => {
      console.log("listener", { location, action });
      if (action === "POP") {
        callback({ location, action });
      }
    };

    const unlisten = navigator.listen(listener);
    return unlisten;
  }, [callback, navigator]);
};

export {
  GetSNSApiUrl,
  GetApiUrlBase,
  GetApiUrl,
  GetUploadApiUrl,
  GetImgUrl,
  GetTempImgUrl,
  getBingoImageUrl,
  FIRST_PAGE_INDEX, APP_MODE, LOG_ENABLE, APP_VERSION,
  mobileMode,
  mobileOn,
  handleAppNavi,
  withNavigation,
  gotoTalkPage,
  gotoFirstPage,
  useBackListener,
  INSTAGRAM_CHECK_MODE
};